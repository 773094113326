
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { DataItemProps } from 'vuetify'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { ResourceHeaders } from '@/views/datatables/resources/roles'
  import { Entity } from '@/entities'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import dayjs from 'dayjs'
  import _ from 'lodash'

interface RowData extends DataItemProps {
  item: Entity
}

  const sortedData = {
    '@': 'id',
    client: 'name',
    pipeline: 'description',
    saleStatus: 'price',
    purchase: 'auto.name',
    'autoStock.name': 'autoStock.name',
    'sale.auto': 'name',
    'deal.auto': 'name',
    sale: 'auto.name',
    deal: 'auto.name',
    'saleOrder.deal': 'auto.name',
    'purchase.appraisal': 'status.description',
    'sale.order.financing.evaluation': 'status.description',
    agreement: 'value',
    'appraisal.deal.auto': 'name',
    'appraisal.deal.lead.client': 'name',
    appraisalStatus: 'status.description',
    inspectionStatus: 'status.description',
    auto: 'name',
    'auto.name': '@',
    appraisalPrice: 'value',
    inspectionCost: 'value',
    negotiationAmount: 'value',
    'negotiation.negotiationAmount': 'value',
    'negotiation.auto': 'name',
    'negotiation.client': 'name',
    price: 'value',
    'saleOrder.deal.auto': 'name',
    'saleOrder.deal.auto.autoStatus': 'price',
    startingAmount: 'value',
    evaluation: 'status.description',
    'sale.auto.autoStatus': 'status.description',
    discount: 'value',
    alerts: 'activityAlert.order',
    lastLeadActivityOutDate: '@',
    'auto.year': 'auto.year',
    'deal.auto.year': 'auto.year',
    priceAmount: 'price.amount',
  }

@Component({
  components: {
    ActionCell: () => import('./cell/ActionCell.vue'),
    ExpandedRow: () => import('./ExpandedRow.vue'),
    TextCell: () => import('./cell/TextCell.vue'),
    DateCell: () => import('./cell/DateCell.vue'),
    StatusCell: () => import('./cell/StatusCell.vue'),
    LinkedCell: () => import('./cell/LinkedCell.vue'),
    ExpandCell: () => import('./cell/ExpandCell.vue'),
    PriceCell: () => import('./cell/PriceCell.vue'),
    IconCell: () => import('./cell/IconCell.vue'),
    StockCell: () => import('./cell/StockCell.vue'),
    ProgressCell: () => import('./cell/ProgressCell.vue'),
    AddressCell: () => import('./cell/AddressCell.vue'),
    RatingCell: () => import('./cell/RatingCell.vue'),
    AlertCell: () => import('./cell/AlertCell.vue'),
    ListComponentCell: () => import('./cell/ListComponentCell.vue'),
    InspectionSummaryCell: () => import('./cell/InspectionSummaryCell.vue'),
    PhotoCell: () => import('./cell/PhotoCell.vue'),
    LetterApprovalCell: () => import('./cell/LetterApprovalCell.vue'),
    BackupPaymentCell: () => import('./cell/BackupPaymentCell.vue'),
    ChipsCell: () => import('./cell/ChipCell.vue'),
    StockDetailCell: () => import('./cell/stockDetailCell.vue'),
    PdfReserveCell: () => import('./cell/reservePDFCell.vue'),
    AlertReserveSellCell: () => import('./cell/AlertReserveSell.vue'),
    PdfSaleCell: () => import('./cell/salePDFCell.vue'),
    PdfResponsabilityCell: () => import('./cell/responsabilityPdfCell.vue'),
    PdfPurchaseCell: () => import('./cell/purchasePDFCell.vue'),
    PdfWarrantyCell: () => import('./cell/warrantyPDFCell.vue'),
    PdfConsignmentCell: () => import('./cell/consignmentPDFCell.vue'),
    InspectionPdfCell: () => import('./cell/InspectionPdfCell.vue'),
    PdfConsignmentWithdrawalCell: () => import('./cell/consignmentWithdrawalPDFCell.vue'),
    ConsignmentCell: () => import('./cell/ConsignmentCell.vue'),
    ConsignmentExpirationCell: () => import('./cell/ConsignmentExpirationCell.vue'),
    StockButtonCell: () => import('./cell/StockButtonCell.vue'),
    DailyAccountAlertCell: () => import('./cell/DailyAccountAlert.vue'),
    StockDailyCell: () => import('./cell/StockDaily.vue'),
    StockLeadReceivedCell: () => import('./cell/StockLeadReceived.vue'),
    AlertConsignmentCell: () => import('./cell/AlertConsignment.vue'),
    PurchaseStatusCell: () => import('./cell/PurchaseStatusCell.vue'),
    StockCharacteristicsCell: () => import('./cell/StockCharacteristics.vue'),
    LeadActivityHistoryCell: () => import('./cell/LeadActivityHistory.vue'),
    RedirectDtCell: () => import('./cell/RedirectDt.vue'),
    TotalProfitCell: () => import('./cell/TotalProfit.vue'),
    statusAppraisalCell: () => import('./cell/StatusAppraisalCell.vue'),
    StockDailyProgressCell: () => import('./cell/StockDailyProgress.vue'),
    RetirementConsignmentCell: () => import('./cell/RetirementConsignmentCell.vue'),
    RemoveStockPublicationCell: () => import('./cell/RemoveStockPublication.vue'),
    RepublishStockCell: () => import('./cell/RepublishStock.vue'),
    AlertFinancierCell: () => import('./cell/AlertFinancierCell.vue'),
    AlertStockCell: () => import('./cell/AlertStock.vue'),
    AlertAppraisalCell: () => import('./cell/AlertAppraisal.vue'),
    VoidedPaymentCell: () => import('./cell/VoidedPaymentCell.vue'),
    BackupExpenseCell: () => import('./cell/BackupExpenseCell.vue'),
    StockDetailStatusCell: () => import('./cell/StockDetailStatusCell.vue'),
    CloseEvaluationCell: () => import('./cell/CloseEvaluationCell.vue'),
    StockDocumentationCell: () => import('./cell/StockDocumentationCell.vue'),
    StockDocumentDetailsCell: () => import('./cell/StockDocumentDetailsCell.vue'),
    PublishPlatformCell: () => import('./cell/PublishPlatformCell.vue'),
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
    ...mapMutations('app', ['setProcess']),
  },
  computed: { ...mapGetters('app', ['isMobile']) },
})
  export default class BaseTable extends Vue {
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean, default: true }) readonly single!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly headers!: ResourceHeaders;
  @Prop({ type: Array, default: () => [] }) readonly items!: [];
  @Prop({ type: String, default: '' }) readonly slug!: string;
  @Prop({ type: String, default: '' }) readonly model!: string;
  @Prop({ type: Number, default: 10 }) readonly itemsPerPage!: number;
  @Prop({ type: Number, default: 1 }) readonly page!: boolean;

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  setProcess!: (payload: any) => void

  isMobile!: boolean
  change = false
  defaultSortBy: string = '';
  sortBy: string | null = null; // Columna activa para ordenar
  sortDesc: boolean | null = null; // Dirección del ordenamiento (true: descendente, false: ascendente, null: sin orden)
  sortedItems: [] = []; // Lista de elementos ordenados
  selectedHeader = null

  cells ({ item, expand, isExpanded }: RowData) {
    const { headers } = this

    const disabled = item.isClosed
    return headers.map(({ type: name, value: path, options, align }, i) => ({
      key: i,
      name,
      align,
      props: {
        item: getObjectValueByPath(item, path),
        options,
        expand,
        isExpanded,
        disabled,
      },
    }))
  }

  customSort () {
    const path = this.sortBy || this.defaultSortBy
    const sortDesc = this.sortDesc
    const items = _.cloneDeep(this.items) as []

    if (!path?.length) {
      this.sortedItems = items
      return
    }

    if (path === 'alerts') {
      this.sortedItems = items
      return
    }
    items.sort((a, b) => this.compareItems(a, b, path))

    if (!sortDesc) {
      this.sortedItems = items.reverse() as []
      return
    }

    this.sortedItems = items
  }

  getPath (optionsPath: string, headerValue): string {
    // const keys = ['@']
    if (optionsPath?.length) {
      return optionsPath
    }
    return headerValue
  }

  onHeaderClick (header) {
    if (!header.sortable) return // Ignorar encabezados no ordenables
    this.selectedHeader = header.text

    const path = this.getPath(header?.options?.path, header?.value)

    // Cambiar el estado del ordenamiento (true → false → nulsisil)
    if (this.sortBy === path) {
      if (this.sortDesc === true) {
        this.sortDesc = false // Cambia a ascendente
      } else if (this.sortDesc === false) {
        this.sortDesc = null // Cambia a estado inicial (sin orden)
      } else {
        this.sortDesc = true // Cambia a descendente
      }
    } else {
      // Nueva columna seleccionada, inicia con descendente
      this.sortBy = path
      this.sortDesc = true
    }

    this.sortBy = path

    this.customSort() // Ordenar los datos
  }

  compareItems (a, b, path) {
    if (!sortedData[path]) return []

    const [itemA, itemB] = this.getItemComparisonValues(a, b, path)

    return this.compareValues(itemA, itemB)
  }

  getItemComparisonValues (a, b, path) {
    let itemA, itemB

    if (path === '@') {
      itemA = Number(String(a))
      itemB = Number(String(b))
    } else if (sortedData[path].includes('.') || path.includes('.')) {
      const dataA = getObjectValueByPath(a, path)
      const dataB = getObjectValueByPath(b, path)
      if (path !== 'alerts' && typeof dataA === 'object') {
        itemA = getObjectValueByPath(dataA, sortedData[path])
        itemB = getObjectValueByPath(dataB, sortedData[path])
      } else if ((dataA?.length && dataB?.length) || dataA?.toString().length || dataB?.toString().length) {
        itemA = dataA?.[0]?.order || dataA
        itemB = dataB?.[0]?.order || dataB
      }
    } else {
      itemA = getObjectValueByPath(a, path) ? getObjectValueByPath(a, path)[sortedData[path]] : undefined
      itemB = getObjectValueByPath(b, path) ? getObjectValueByPath(b, path)[sortedData[path]] : undefined
    }

    if (itemA !== null && itemA !== undefined && itemB !== null && itemB !== undefined) {
      const valueA = getObjectValueByPath(a, path)
      const valueB = getObjectValueByPath(b, path)
      const dateFormat = 'DD/MM/YYYY HH:mm'

      if (dayjs(valueA, dateFormat, true).isValid() || dayjs(valueB, dateFormat, true).isValid()) {
        itemA = valueA?.length ? dayjs(valueA, dateFormat) : undefined
        itemB = valueB?.length ? dayjs(valueB, dateFormat) : undefined
      }
    }

    itemA = this.handleItemUndefined(itemA, a, path)
    itemB = this.handleItemUndefined(itemB, b, path)

    return [itemA, itemB]
  }

  handleItemUndefined (value, obj, path) {
    return value !== undefined ? value : getObjectValueByPath(obj, path)
  }

  compareDates (itemA, itemB) {
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/
    // Verifica si itemA es una fecha en formato DD/MM/YYYY
    if (typeof itemA === 'string' && dateRegex.test(itemA)) {
      itemA = dayjs(itemA, 'DD/MM/YYYY')
    }

    // Verifica si itemB es una fecha en formato DD/MM/YYYY
    if (typeof itemB === 'string' && dateRegex.test(itemB)) {
      itemB = dayjs(itemB, 'DD/MM/YYYY')
    }

    // Comparación cuando ambos son fechas válidas
    if (dayjs.isDayjs(itemA) && dayjs.isDayjs(itemB)) {
      if (itemA.isAfter(itemB)) return -1
      if (itemA.isBefore(itemB)) return 1
      return 0
    }

    // Comparación estándar
    if (itemA < itemB) return -1
    if (itemA > itemB) return 1
    return 0
  }

  compareValues (itemA, itemB) {
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/
    if ((typeof itemA === 'string' && dateRegex.test(itemA)) || (typeof itemB === 'string' && dateRegex.test(itemB))) {
      return this.compareDates(itemA, itemB)
    }

    if (itemA === undefined && itemB === undefined) return this.isLead ? 0 : 1

    // Si uno de los dos es undefined, el undefined va al final
    if (itemA === undefined) return this.isLead ? 1 : -1
    if (itemB === undefined) return this.isLead ? -1 : 1

    // Comparar instancias de Dayjs si ambos son fechas
    if (itemA instanceof dayjs || itemB instanceof dayjs) {
      const isAValid = itemA instanceof dayjs && itemA.isValid()
      const isBValid = itemB instanceof dayjs && itemB.isValid()

      if (isAValid && isBValid) {
        // Orden descendente: la fecha más reciente primero
        if (itemA.isAfter(itemB)) return -1
        if (itemA.isBefore(itemB)) return 1
        return 0 // Si son iguales
      }
      // Si solo una es dayjs válida, se prioriza la fecha válida
      if (!isAValid && isBValid) return 1 // itemA no es fecha válida, lo ubica después
      if (isAValid && !isBValid) return -1 // itemB no es fecha válida, itemA va antes

      // Si ninguno es dayjs válido, se puede comparar de otra forma o dejarlos igual
      return 0
    }

    // Comparar los valores normalmente
    if (itemA < itemB) return -1
    if (itemA > itemB) return 1

    return 0
  }

  transformString (input) {
    if (!input) return input

    const hasSecondUppercase = /[A-Z].*[A-Z]/.test(input)

    if (hasSecondUppercase) {
      return input.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
    } else {
      return input.toLowerCase()
    }
  }

  checkProcess (process) {
    if (process === 'consignment') {
      return 'purchase_order'
    }

    if (process === 'expense_order') {
      return 'payment'
    }

    return process
  }

  @Watch('model', { deep: true, immediate: true })
  async onModelChange (val) {
    if (!val) return
    const processName = this.checkProcess(this.transformString(val))

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: processName } },
      force: true,
    })

    this.setProcess(process[0])
  }

  @Watch('slug', { deep: true, immediate: true })
  onSlugChange (val) {
    if (!val) return
    this.defaultSortBy = this.isLead ? 'lastLeadActivityOutDate' : ''
  }

  get isLead () {
    const { slug } = this
    const leadSlugs = ['staff_leads', 'supervisor_leads']

    return leadSlugs.includes(slug)
  }

  @Watch('items', { immediate: true })
  onItemsChange () {
    this.customSort()
  }

  @Watch('$route.query.sortBy', { immediate: true })
  onSortByChange (val) {
    if (!val) return
    this.sortBy = val
  }
  }
